
import './App.css';
import './assets/styles/home.css';
import { useEffect, useState } from 'react';




function App() {
	
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const today = new Date()
	const mydeadline = new Date("2024-07-05 00:00:00")
	
	

	// console.log('eventTime >>>', eventTime)



	const leading0 = (num) => {
		return num < 10 ? "0" + num : num;
	};

	const getTimeUntil = () => {
		// const deadline =  new Date("2024-07-05 00:00:00")
		const deadline =  mydeadline;
		const time = Date.parse(deadline) - Date.parse(new Date());
		if (time < 0) {
			console.log('time >>')
			setHours(0);
			setMinutes(0);
			setSeconds(0);
		} else {			
			setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
			setMinutes(Math.floor((time / 1000 / 60) % 60));
			setSeconds(Math.floor((time / 1000) % 60));
		}
	};

	useEffect(() => {
		// let start = new Date()
		// let end = new Date("2024-07-05 00:00:00")
		// const time = Date.parse(end) - Date.parse(new Date());
		// console.log('time is >>>>', time)
		// if (time < 0){
		// }
		let myDate = new Date()
		if (myDate < mydeadline){
			console.log('kena exec')
			setInterval(() => getTimeUntil(), 1000);
				
			return () => getTimeUntil();	
		}
		// eslint-disable-next-line 	
	}, []);
	// console.log('today >>>', today)
	// console.log('mydeadline >>>', mydeadline)


	return (
		<div className="App bg-gradient-to-b from-[#BEB8CD] to-[#8286A4] w-screen min-h-screen h-full  overflow-hidden flex flex-col justify-center items-center relative">
			<div className="absolute z-0 -bottom-5 -left-5"><img src={require('./assets/images/trumpeth.png')} alt="balloons" className='w-[100px] h-[100px] object-contain'/></div>
			<div className="absolute z-10 top-[calc(100vh*.6)] left-10 md:top-[calc((100vh*.6)-100px)] md:left-[calc((100vw*0.5)-300px)]"><img src={require('./assets/images/birthday-cake.png')} alt="balloons" className='md:w-[200px] md:h-[200px] w-[100px] h-[100px] object-contain'/></div>
			<div className="absolute z-0 top-[calc(100vh*.3)]"><img src={require('./assets/images/balloonsthree.png')} alt="balloons" className='w-[200px] h-[200px] object-contain'/></div>
			<div className="absolute z-0 top-20 left-0 "><img src={require('./assets/images/dark-blue-balloon.png')} alt="balloons" className='w-[200px] h-[200px] object-contain'/></div>
			<div className="absolute z-0 top-10 -right-7 "><img src={require('./assets/images/goldballoon.png')} alt="balloons" className='w-[200px] h-[200px] object-contain'/></div>
			
			<div className='bg-white/20 absolute top-[calc(-2.5rem-30px)]  w-[150px] h-[150px] rounded-full z-10 left-[calc((100%*.5)-25px)] backdrop-blur-sm shadow-xl'></div>
			<div className='bg-red-200 absolute -top-10 w-[100px] h-[100px] rounded-full  left-1/2'></div>

			<div className='bg-white/20 absolute top-[calc(-2.5rem-50px)]  w-[140px] h-[140px] rounded-full z-10 -left-[25px] backdrop-blur-sm shadow-xl'></div>
			<div className='bg-red-200 absolute -top-16 w-[80px] h-[80px] rounded-full  left-0'></div>
			{ today < mydeadline &&
				<div className="flex flex-row text-[52px] font-bold text-white z-10 mb-20">
					<div>{leading0(hours).toString()}</div>
					<div>:</div>
					<div>{leading0(minutes).toString()}</div>
					<div>:</div>
					<div>{leading0(seconds).toString()}</div>
				</div>
			}
			
			<div className={`${today > mydeadline ? 'mt-[230px]' : 'mt-[70px]' } rounded-full overflow-hidden w-[300px] h-[300px] md:w-[calc(100vh*0.5)] md:h-[calc(100vh*0.5)]  bg-gradient-to-r from-[#6775A4] to-[#8688B4] border border-white flex flex-col justify-center items-center text-white text-[26px] relative shadow-xl`}>
				<div className='mt-4'>HAPPY BIRTHDAY</div>
				<div className='font-bold text-[38px]'>NINO</div>
				<div className='text-[18px] font-bold mt-[10px]'>5 JULI 2024</div>
			</div>
			<div className='mt-10 w-[calc(100vh*0.5)] text-[#FFFFFF] px-[40px] font-bold'>
				{/* <div className='font-dancingScript text-[20px] md:text-[32px] font-bold text-white leading-6 md:leading-10'>Setiap tahun adalah kesempatan untuk tumbuh menjadi versi terbaik dari dirimu.</div> */}
				<div className='font-dancingScript text-[18px] md:text-[32px] font-bold text-white leading-6 md:leading-10'>Hal yang benar-benar ingin aku lakukan dalam hidupku adalah memastikan kamu selalu bahagia bersamaku. Bukan hanya hari ini di hari ulang tahunmu, tetapi juga di hari-hari lainnya. Selamat ulang tahun, istriku!</div>
				{/* <div className='font-dancingScript text-[20px] md:text-[32px] font-bold text-white leading-6 md:leading-10 mt-2'>I Love You</div>
				<div className='font-dancingScript text-[20px] md:text-[32px] font-bold text-white leading-6 md:leading-10 mt-2'>From Your Husband</div> */}
			</div>
			
		</div>
	);
}

export default App;
